import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../../components/contentwrapper/ContentWrapper'
import { useParams } from 'react-router-dom'
import { fetchAllDataByIDAPI, skillFilterByNameAPI } from '../../utils/api'
import Skeleton from 'react-loading-skeleton'
import { numberMasking } from '../../components/numberMasking'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { MdRefresh } from 'react-icons/md'

const SkillDetails = () => {
    const ref = useRef(null);
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [skill, setSkill] = useState([]);
    const [searchvalues, setSearchValue] = useState({});
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        getSkillDetails(slug);
    }, [slug]);

    const getSkillDetails = (slug) => {
        setIsLoading(true);
        fetchAllDataByIDAPI(`ppc-get-ngucc-skill-details/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setSkill(...[res.data.skills]);
            }
            else {
                setIsLoading(false)
            }
        })
    }
    const handleInputs = async (e) => {
        setSearchValue({
            ...searchvalues,
            [e.target.name]: e.target.value,
        });
    };
    const handleSearchBySkill = (event) => {
        setIsLoading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const datavalue = {
                start_date: searchvalues?.start_date,
                end_date: searchvalues?.end_date,
                skill_name: slug,
            };
            skillFilterByNameAPI('ppc-ngucc-filter-by-skill-name', datavalue).then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false)
                    setSkill(...[res.data.skills]);
                }
                else {
                    setIsLoading(false)
                    getSkillDetails(slug);
                }
            })
        }
        setValidated(true);
    }
    const resetFilterForm = () => {
        ref.current.reset();
        getSkillDetails(slug);
    }
    return (
        <div className="companypage">
            <Helmet>
                <title>SATKARTAR:SUBADMIN::SKILL DETAILS</title>
            </Helmet>
            <ContentWrapper>
                <>
                    <div className="card filterskill">
                        <Form ref={ref} validated={validated} noValidate method="POST" onSubmit={handleSearchBySkill}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="startdate">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Name"
                                        required
                                        name="start_date"
                                        onChange={handleInputs}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="enddate">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Enter mobile number"
                                        required
                                        name="end_date"
                                        onChange={handleInputs}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="d-flex justify-content-center m-auto">
                                    <div style={{ marginTop: "39px" }} className="btnfilters">
                                        <Button type="submit" className="btn-md">Search</Button>
                                        <span onClick={resetFilterForm} className="btn btn-md btnreset"><MdRefresh /> Reset</span>
                                    </div>
                                </Form.Group>
                            </Row>
                        </Form>
                    </div>
                    <div className="card hrpages">
                        <div className="heading p-2 d-flex justify-content-between mb-2">
                            <h5 className="mb-0 text-uppercase">{slug} Details ({skill.length})</h5>
                        </div>
                        <div className="table pagetable table-responsive">
                            <table id="example" className="table table-striped table-bordered table-sm" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Mobile</th>
                                        <th>Remarks</th>
                                        <th>Call Type</th>
                                        <th>Call Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                        </>
                                        :
                                        skill ?
                                            skill?.map((skill, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{numberMasking(skill?.phoneNumber)}</td>
                                                    <td>{skill?.dispositionType}</td>
                                                    <td>{skill?.callType}</td>
                                                    <td>{skill?.talkDuration}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={5} className="text-center">Data not founds...</td>
                                            </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Mobile</th>
                                        <th>Remarks</th>
                                        <th>Call Type</th>
                                        <th>Call Duration</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </>
            </ContentWrapper>
        </div>
    )
}

export default SkillDetails