import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { fetchAllDataByIDAPI } from '../../utils/api'
import Skeleton from 'react-loading-skeleton'
import { Badge } from 'react-bootstrap'

const AllProcessName = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [process, setProcess] = useState([]);
    const ppcid = localStorage.getItem('ppcdata');

    useEffect(() => {
        getProcessDetails(ppcid);
    }, [ppcid]);

    const getProcessDetails = (ppcid) => {
        setIsLoading(true);
        fetchAllDataByIDAPI(`ppc-get-all-process-data/${ppcid}`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setProcess(...[res.data.process]);
            }
            else {
                setIsLoading(false)
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MEMBER::ALL PROCESS</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <div className="heading p-2 d-flex justify-content-between">
                        <h5 className="mb-0 text-uppercase">Today Process Details</h5>
                    </div>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped table-bordered table-sm" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Process Name</th>
                                    <th>Mobile</th>
                                    <th>Language</th>
                                    <th>Product</th>
                                    <th>Handle By</th>
                                    <th>Status</th>
                                    <th>Total Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                    </>
                                    :
                                    process ?
                                        process?.map((process, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td><Link target="_blank" to={`/ppc-display-skill-details/${process?.process_name}`} className="pcname">{process?.process_name}</Link></td>
                                                <td>{process?.process_mobile}</td>
                                                <td>{process?.language_name}</td>
                                                <td>{process?.process_product}</td>
                                                <td>{process?.process_handle_by}</td>
                                                <td>
                                                    {(() => {
                                                        if (process?.process_status === "Active") {
                                                            return (
                                                                <span className="badge bg-success statustype">{process?.process_status}</span>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <span className="badge bg-danger statustype">{process?.process_status}</span>
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                                <td><Badge>{process?.totalcall}</Badge></td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={10} className="text-center">Data not founds...</td>
                                        </tr>
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Process Name</th>
                                    <th>Mobile</th>
                                    <th>Language</th>
                                    <th>Product</th>
                                    <th>Handle By</th>
                                    <th>Status</th>
                                    <th>Total Call</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AllProcessName