import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import MobileOTP from "./pages/MobileOTP";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MyProfile from "./pages/MyProfile";
import 'react-loading-skeleton/dist/skeleton.css'
import AllProcessName from "./pages/process/AllProcessName";
import SkillDetails from "./pages/process/SkillDetails";



function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/all-process" element={<AllProcessName />} />
          <Route path="/ppc-display-skill-details/:slug" element={<SkillDetails />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
