import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { useNavigate } from 'react-router-dom'
import { getSingleDataFromDatabase, userUploadProfilePicAPI } from '../utils/api'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { BASEURLS } from '../utils/BaseUrl'
import moment from 'moment'
import { MdCloudUpload } from 'react-icons/md'

const MyProfile = () => {
    const ppcid = localStorage.getItem('ppcdata');
    const [ppc, setPPC] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);
    const [file, setFile] = useState('');

    useEffect(() => {
        if (ppcid) {
            getDashboardSingleEmpData(ppcid);
        }
        else {
            navigate("/");
        }
    }, [ppcid]);

    const getDashboardSingleEmpData = (ppcid) => {
        setIsloading(true);
        getSingleDataFromDatabase(`ppc-get-single-data/${ppcid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setPPC(...[res.data.ppcdata]);
            }
            else {
                setIsloading(true);
                toast.error("Check you connection");
            }
        })
    }
    // Update Image
    useEffect(() => {
        if (file) {
            try {
                const data = new FormData();
                data.append("ppc_foto", file);
                userUploadProfilePicAPI(`ppc-update-profile-picture-data/${ppcid}`, data).then((res) => {
                    if (res.data.status === 1) {
                        window.location.reload();
                        toast.success("profile pic updated successfully");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [file]);

    return (
        <div className="myprofile">
            <Helmet>
                <title>SATKARTAR:DISPATCH::MY PROFILE</title>
            </Helmet>
            <ContentWrapper>
                <div className="row">
                    <div className="col-6 col-lg-6 col-xl-6">
                        <div className="card radius-10 overflow-hidden w-100">
                            <div className="card-header border-bottom bg-transparent">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h6 className="mb-0" style={{ color: "#03a9f4", fontWeight: "500" }}>My Profile</h6>
                                    </div>
                                </div>
                            </div>
                            {isLoading ?
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-4 col-xl-4">
                                            <div className="profileimg">
                                                <img src={BASEURLS(ppc?.ppc_foto)} alt="" />
                                                <label className="labelProfilePic btn btn-sm btn-info" htmlFor="mis_foto"><MdCloudUpload /> Change Profile Picture</label>
                                                <input accept='image/*' className="profilePic" type="file" id='mis_foto' name="mis_foto" onChange={(e) => setFile(e.target.files[0])} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8 col-xl-8">
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Name:</span>
                                                        <span className="value">{ppc?.ppc_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Email:</span>
                                                        <span className="value badge badge-success">{ppc?.ppc_email}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Mobile:</span>
                                                        <span className="value">{ppc?.ppc_mobile}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Last Login Date:</span>
                                                        <span className="value">{moment(ppc?.ppc_last_login).format('Do MMMM YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MyProfile